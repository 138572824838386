import cliTruncate from "cli-truncate"
import React, { useEffect } from "react"
const CourseBannerCarousal = () => {
  useEffect(() => {
    const { $ } = window
    $(".study-slider").owlCarousel({
      loop: true,
      margin: 30,
      nav: true,
      dots: false,
      autoplay: false,
      smartSpeed: 1000,
      autoplayHoverPause: true,
      navText: [
        "<i class='ri-arrow-left-line'></i>",
        "<i class='ri-arrow-right-line'></i>",
      ],
      responsive: {
        0: { items: 1 },
        414: { items: 1 },
        576: { items: 2 },
        768: { items: 2 },
        992: { items: 3 },
        1400: { items: 3 },
      },
    })
  }, [])
  return (
    <section className="study-area pt-100 pb-70">
      <div className="container">
        <div className="section-title white-title">
          <h2>Browse courses by study area</h2>
          <p style={{ fontSize: 20 }}>
            Courses in Science, Arts and Commerce stream are availabe in both
            English and Assamese Medium.
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="study-slider owl-carousel owl-theme">
            <div className="single-study">
              <i className="flaticon-finance"></i>
              <h3>
                <a href="/courses">Science</a>
              </h3>
              <p>
                {cliTruncate(
                  `We have popular and experienced faculties of Physics, Chemistry,
                    Maths and Biology for teaching and councelling in Science
                    Stream.`,
                  150
                )}
              </p>
              <a href="/courses" className="read-more">
                Find out more
                <span className="ri-arrow-right-line"></span>
              </a>
            </div>
            <div className="single-study">
              <i className="flaticon-data-scientist"></i>
              <h3>
                <a href="/courses">Commerce</a>
              </h3>
              <p>
                {cliTruncate(
                  `We aim to guide students at primary level with modern day commerce, business and Economics 
                  for their future Role and Contributions towards shaping India's economy.`,
                  100
                )}
              </p>
              <a href="/courses" className="read-more">
                Find out more
                <span className="ri-arrow-right-line"></span>
              </a>
            </div>
            <div className="single-study">
              <i className="flaticon-compliant"></i>
              <h3>
                <a href="/courses">Arts</a>
              </h3>
              <p>
                {cliTruncate(
                  `We are educating students how literature, history, art, music,
                  religion and culture have shaped the modern world in a new way 
                  with our enthusiastic and expert faculties on each discipline`,
                  150
                )}
              </p>
              <a href="/courses" className="read-more">
                Find out more
                <span className="ri-arrow-right-line"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CourseBannerCarousal
