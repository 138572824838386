import React from "react"
import styled from "styled-components"
import FAQList from "../FAQList"
import NewsList from "../NewsList"

const EventSection = styled.section`
  padding: 50px 0;
`

const EventList = () => {
  return (
    <EventSection className="events-area bg-color">
      <div className="container">
        <div className="row align-items-stretch">
          <div className="col-12 col-md-6 pt-5">
            <div className="events-content">
              <NewsList limit={3} />
            </div>

            <a href="/noticeboard" className="default-btn mt-4">
              View All
            </a>
          </div>

          <div className="col-12 col-md-6 pt-5">
            {/* <span>Events</span> */}
            <h3>Fequently Asked Questions</h3>

            <FAQList max={6} />
          </div>
        </div>
      </div>
    </EventSection>
  )
}

export default EventList
