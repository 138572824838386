import React from "react"
const HomeMainBanner = () => {
  return (
    <section
      className="banner-area bg-1 jarallax"
      data-jarallax='{"speed": 0.3}'
    >
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container" style={{ maxWidth: 1300 }}>
            <div className="row">
              <div className="col-lg-6 pb-5">
                <div className="banner-content">
                  <span style={{ fontSize: 22, fontStyle: "italic" }}>
                    Survival of the Fittest - Charles Darwin
                  </span>
                  <h1>
                    Rethinking Modern Education for making students Competent
                    and Capable
                  </h1>
                  <div className="banner-btn">
                    <a href="/about" className="default-btn">
                      READ MORE
                    </a>
                  </div>
                  {/* 
                   <div className="courses-link">
                        <a href="campus-experience.html">
                            Explore our courses
                            <i className="ri-arrow-right-line"></i>
                        </a>
                        <a href="study-online.html" className="study-online">
                            Study online
                            <i className="ri-arrow-right-line"></i>
                        </a>
                    </div> 
                    */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="banner-img">
                  <img src="/images/practical_image_1.jpg" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeMainBanner
