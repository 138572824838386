import cliTruncate from "cli-truncate"
import React, { useEffect } from "react"
import styled from "styled-components"
import CourseBannerCarousal from "../components/home/CourseCarousal"
import EventList from "../components/home/EventList"
import HomeMainBanner from "../components/home/MainBanner"
import ScholarshipBanner from "../components/ScholarshipBanner"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"

const YoutubePrincipalMessage = styled.div`
  position: absolute;
  font-size: 60px !important;
  bottom: 0;
  color: #fff;
  text-align: center;
  width: 100%;

  a {
    font-size: 60px !important;
    width: 80px;
    height: 80px;
    i {
      font-size: 60px !important;
    }
  }
`

const HomePage = withLayout(({ siteData }) => {
  useEffect(() => {
    const { $ } = window
    //<iframe width="560" height="315" src="https://www.youtube.com/embed/sfmNkVOZlII" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    $(".popup-youtube, .popup-vimeo").magnificPopup({
      disableOn: 300,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      iframe: {
        markup: `<div class="mfp-iframe-scaler">
                  <div className="mfp-close"></div>
                  <iframe class="mfp-iframe" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>`,
        patterns: {
          youtube: {
            index: "youtube.com/", // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

            id: "v=", // String that splits URL in a two parts, second part should be %id%
            // Or null - full URL will be returned
            // Or a function that should return %id%, for example:
            // id: function(url) { return 'parsed id'; }

            src: "//www.youtube.com/embed/%id%?autoplay=1", // URL that will be set as a source for iframe.
          },
        },
      },
      srcAction: "iframe_src",
    })
  }, [])
  return (
    <>
      <Seo title={"Darwin Academy "} siteMetadata={siteData} />
      <HomeMainBanner />

      <section style={{ marginTop: 100, marginBottom: 100 }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pt-5">
              <div className="about-img-two mr-15">
                <img
                  src="/images/principal.jpg"
                  alt="Image"
                  style={{ border: "4px solid var(--optional-color)" }}
                />
                <YoutubePrincipalMessage>
                  <a
                    href={`https://www.youtube.com/watch?v=sfmNkVOZlII`}
                    className="popup-youtube video-btn"
                  >
                    <i className="flaticon-play-button"></i>
                  </a>
                </YoutubePrincipalMessage>
              </div>
            </div>
            <div className="col-lg-6 pt-5">
              <div
                className="about-content ml-15"
                style={{ textAlign: "justify" }}
              >
                <span>From Principals Desk</span>
                <h3>Dr. Ramesh Ch. Kalita</h3>
                <p>
                  Education means ideas, it is not about bricks, mortar etc, but
                  building character of a perfect human being with a great
                  idealism an institution namely “Darwin Academy, Mirza” was
                  born. We also take utmost care in moulding our students as
                  responsible global citizen.{" "}
                  <em>
                    “If a plant is carefully nurtured by a gardener, it will
                    become good and produce better fruits.”
                  </em>{" "}
                  Therefore, children must be given a good training from their
                  earliest. We are here for the same. So, I as a Principal,
                  advise every student to do the work with intention not because
                  of compulsion. Most importantly, at the institute, we are a
                  team of dedicated teachers and staff committed to the welfare
                  of the students in our care.
                </p>
                <p>
                  At the end I would like to extend my best wishes to all our
                  students, staff &amp; parents.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScholarshipBanner />

      <EventList />

      <CourseBannerCarousal />

      <section className="about-area pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pt-5">
              <div className="about-content ml-15">
                <span>About our College</span>
                <h3>Our education system inspires the next generation</h3>
                <p>
                  The mission of the college is to encourage the students to
                  learn and discover so that they can create . The legendary
                  scientist Darwin said{" "}
                  <strong>
                    <em>“Survival of the fittest”</em>
                  </strong>{" "}
                  and based on this concept our mission is to make everyone
                  competent and capable of realising incoming opportunities in
                  the years to come, enriching individual lives of students and
                  becoming dedicated leaders to serve the society.
                </p>
                <p>
                  Our Vision is to provide quality equation by experienced
                  teachers to the students at an affordable fees and to enhance
                  the personality and skill of the students in its true sense.
                </p>
                <a href="/about" className="default-btn">
                  Know More
                </a>
              </div>
            </div>
            <div className="col-lg-6 pt-5">
              <div className="about-img">
                <img src="/images/about-img.jpg" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
})

export default HomePage
